import React, { FC } from 'react';
import './aboutUs.scss';
import Layouts from '../../components/layout';
import SectionHeader from '../../components/pagesComponents/HowWeWork/SectionHeader';
import TextDescription from '../../components/common/TextDescription';
import triangleRight from '../../assets/img/triangle-right.svg';
import Button from '../../components/common/Button';
import workingPeople from '../../assets/img/aboutUs/offices-img/IMG_2994.jpg';
import Job from '../../assets/img/aboutUs/section-img/job.svg';
import illustration from '../../assets/img/aboutUs/section-img/illustration.png';
import arrRight from '../../assets/img/arr-right.svg';
import SectionTitle from '../../components/common/SectionTitle';
import TextSpan from '../../components/pagesComponents/MainPage/TextSpan';
import TextImage from '../../components/pagesComponents/WorkingTogether/TextImage';
import PhotoIcon from '../../components/pagesComponents/AboutUs/PhotoIcon';
import office1 from '../../assets/img/aboutUs/offices-img/IMG_2993.jpg';
import office2 from '../../assets/img/aboutUs/offices-img/IMG_2995.jpg';
import office4 from '../../assets/img/aboutUs/offices-img/IMG_3005.jpg';
import office3 from '../../assets/img/aboutUs/offices-img/IMG_2997.jpg';
import aboutUsBG from '../../assets/img/aboutUs/section-img/about-us-bg.svg';
import YuriiDrozd from '../../assets/img/aboutUs/staff/PM/YuriiDrozd.jpeg';
import OleksiiHnatiienko from '../../assets/img/aboutUs/staff/PM/OleksiiHnatiienko.png';
import AndriiTrykopa from '../../assets/img/aboutUs/staff/PM/AndriiTrykopa.png';
import KaterynaBilkovets from '../../assets/img/aboutUs/staff/PM/KaterynaBilkovets.jpg';
import OlhaMotovylova from '../../assets/img/aboutUs/staff/PM/OlhaMotovylova.png';

import DmytriiStepanenko from '../../assets/img/aboutUs/staff/PM/DmytriiStepanenko.jpeg';
import OlenaPortnova from '../../assets/img/aboutUs/staff/PM/OlenaPortnova.jpeg';
import VadymKlymenko from '../../assets/img/aboutUs/staff/PM/VadymKlymenko.jpeg';
import SergeyGorbachev from '../../assets/img/aboutUs/staff/QA/SergeyGorbachev.jpeg';
import RuslanaChumachenko from '../../assets/img/aboutUs/staff/QA/RuslanaChumachenko.jpeg';
import PolinaSobolevska from '../../assets/img/aboutUs/staff/QA/PolinaSobolevska.jpeg';
import ElenaZolotoverkh from '../../assets/img/aboutUs/staff/QA/ElenaZolotoverkh.jpeg';
import DmytroMykolaienko from '../../assets/img/aboutUs/staff/QA/DmytroMykolaienko.jpeg';
import DmytroDeleur from '../../assets/img/aboutUs/staff/QA/DmytroDeleur.jpeg';
import AnastasiiaShyshak from '../../assets/img/aboutUs/staff/QA/AnastasiiaShyshak.jpeg';
import OleksandrPavliuk from '../../assets/img/aboutUs/staff/Developers/OleksandrPavliuk.jpeg';
import AndriiHrushko from '../../assets/img/aboutUs/staff/Developers/AndriiHrushko.jpeg';
import AntonShloma from '../../assets/img/aboutUs/staff/Developers/AntonShloma.jpeg';
import DmytroBogdan from '../../assets/img/aboutUs/staff/Developers/DmytroBogdan.jpeg';
import DmytroZakharchenko from '../../assets/img/aboutUs/staff/Developers/DmytroZakharchenko.jpeg';
import GlebGerasimenko from '../../assets/img/aboutUs/staff/Developers/GlebGerasimenko.jpeg';
import JairoPanduro from '../../assets/img/aboutUs/staff/Developers/JairoPanduro.jpeg';
import KostiantynSamkovskyi from '../../assets/img/aboutUs/staff/Developers/KostiantynSamkovskyi.jpeg';
import OleksandrLomakovskyi from '../../assets/img/aboutUs/staff/Developers/OleksandrLomakovskyi.jpeg';
import SergeyTovstonog from '../../assets/img/aboutUs/staff/Developers/SergeyTovstonog.jpeg';
import SerhiiZiniuk from '../../assets/img/aboutUs/staff/Developers/SerhiiZiniuk.jpeg';
import StanislavNosovskiy from '../../assets/img/aboutUs/staff/Developers/StanislavNosovskiy.jpeg';
import VasylStepanyuk from '../../assets/img/aboutUs/staff/Developers/VasylStepanyuk.jpeg';
import ViacheslavBychkovskyi from '../../assets/img/aboutUs/staff/Developers/ViacheslavBychkovskyi.jpeg';
import YevgeniyRyzhov from '../../assets/img/aboutUs/staff/Developers/YevgeniyRyzhov.jpeg';
import YuriiBalashkevych from '../../assets/img/aboutUs/staff/Developers/YuriiBalashkevych.jpeg';
import AnastasiiaIlina from '../../assets/img/aboutUs/staff/Developers/AnastasiiaIlina.jpeg';
import AnatoliiOlshevskyi from '../../assets/img/aboutUs/staff/Developers/AnatoliiOlshevskyi.jpeg';
import AndriiAndriienko from '../../assets/img/aboutUs/staff/Developers/AndriiAndriienko.jpeg';
import IvanKuzmenko from '../../assets/img/aboutUs/staff/Developers/IvanKuzmenko.jpeg';
import MaksymGrytsuk from '../../assets/img/aboutUs/staff/Developers/MaksymGrytsuk.jpeg';
import MaximSobolev from '../../assets/img/aboutUs/staff/Developers/MaximSobolev.jpeg';
import MykytaDidenko from '../../assets/img/aboutUs/staff/Developers/MykytaDidenko.jpeg';
import OleksandrGribov from '../../assets/img/aboutUs/staff/Developers/OleksandrGribov.jpeg';
import PetroRudenko from '../../assets/img/aboutUs/staff/Developers/PetroRudenko.jpeg';
import SergeyKlymenko from '../../assets/img/aboutUs/staff/Developers/SergeyKlymenko.jpeg';
import TatianaPavlenko from '../../assets/img/aboutUs/staff/Developers/TatianaPavlenko.jpeg';
import TetianaKlymets from '../../assets/img/aboutUs/staff/Developers/TetianaKlymets.jpeg';
import ValeriiZavaruiev from '../../assets/img/aboutUs/staff/Developers/ValeriiZavaruiev.jpeg';
import VolodymyrPuchkov from '../../assets/img/aboutUs/staff/Developers/VolodymyrPuchkov.jpeg';
import MaksymTymchenko from '../../assets/img/aboutUs/staff/Developers/MaksymTymchenko.jpg';
import AnnaTrofimova from '../../assets/img/aboutUs/staff/Developers/AnnaTrofimova.jpeg';
import AnastasiiaIvanova from '../../assets/img/aboutUs/staff/Other/AnastasiiaIvanova.jpeg';
import KarinaDubok from '../../assets/img/aboutUs/staff/Other/KarinaDubok.jpeg';
import AndriiOvsiannikov from '../../assets/img/aboutUs/staff/Data/AndriiOvsiannikov.jpeg';
import map from '../../assets/img/aboutUs/section-img/map.png';

import { TOurTeam } from '../../types/types';
import { Link } from 'gatsby';
import FixedButton from '../../components/common/FixedButton';
import SEO from '../../components/SEO';

const aboutUs: FC = () => {
    const ourTeam: TOurTeam = [
        { id: 0, name: 'Yurii Drozd', position: 'CEO', img: YuriiDrozd },
        { id: 1, name: 'Dmytrii Stepanenko', position: 'Technical PM', img: DmytriiStepanenko },
        { id: 2, name: 'Olena Portnova', position: 'Technical PM', img: OlenaPortnova },
        { id: 3, name: 'Vadym Klymenko', position: 'Technical PM', img: VadymKlymenko },
        { id: 4, name: 'Andrii Trykopa', position: 'Technical PM', img: AndriiTrykopa },
        { id: 5, name: 'Oleksii Hnatiienko', position: 'Technical PM', img: OleksiiHnatiienko },
        {
            id: 6,
            name: 'Kateryna Bilkovets',
            position: 'Recruitment Lead',
            img: KaterynaBilkovets,
        },
        { id: 7, name: 'Olha Motovylova', position: 'HR Business Partner', img: OlhaMotovylova },
    ];

    const ourTalanted: TOurTeam = [
        { id: 0, name: 'Sergey G.', position: 'Senior QA', img: SergeyGorbachev },
        { id: 1, name: 'Ruslana C.', position: 'Senior QA', img: RuslanaChumachenko },
        { id: 2, name: 'Andrii T.', position: 'Senior QA', img: AndriiTrykopa },
        { id: 3, name: 'Polina S.', position: 'QA', img: PolinaSobolevska },
        { id: 4, name: 'Elena Z.', position: 'QA', img: ElenaZolotoverkh },
        { id: 5, name: 'Dmytro M.', position: 'QA', img: DmytroMykolaienko },
        { id: 6, name: 'Dmytro D.', position: 'QA', img: DmytroDeleur },
        { id: 7, name: 'Anastasiia S.', position: 'QA', img: AnastasiiaShyshak },
        { id: 8, name: 'Viacheslav B.', position: 'Lead Developer', img: ViacheslavBychkovskyi },
        { id: 10, name: 'Anton S.', position: 'Senior Developer', img: AntonShloma },
        { id: 11, name: 'Dmytro B.', position: 'Senior Developer', img: DmytroBogdan },
        { id: 12, name: 'Dmytro Z.', position: 'Senior Developer', img: DmytroZakharchenko },
        { id: 13, name: 'Gleb G.', position: 'Senior Developer', img: GlebGerasimenko },
        { id: 14, name: 'Jairo P.', position: 'Senior Developer', img: JairoPanduro },
        { id: 15, name: 'Kostiantyn S.', position: 'Senior Developer', img: KostiantynSamkovskyi },
        { id: 16, name: 'Oleksandr L.', position: 'Senior Developer', img: OleksandrLomakovskyi },
        { id: 17, name: 'Sergey T.', position: 'Senior Developer', img: SergeyTovstonog },
        { id: 18, name: 'Serhii Z.', position: 'Senior Developer', img: SerhiiZiniuk },
        { id: 19, name: 'Stanislav N.', position: 'Senior Developer', img: StanislavNosovskiy },
        { id: 20, name: 'Vasyl S.', position: 'Senior Developer', img: VasylStepanyuk },
        { id: 21, name: 'Oleksandr P.', position: 'Senior Developer', img: OleksandrPavliuk },
        { id: 22, name: 'Yevgeniy R.', position: 'Senior Developer', img: YevgeniyRyzhov },
        { id: 23, name: 'Yurii B.', position: 'Senior Developer', img: YuriiBalashkevych },
        { id: 24, name: 'Anastasiia I.', position: 'Developer', img: AnastasiiaIlina },
        { id: 25, name: 'Anatolii O.', position: 'Developer', img: AnatoliiOlshevskyi },
        { id: 26, name: 'Andrii A.', position: 'Developer', img: AndriiAndriienko },
        { id: 27, name: 'Ivan K.', position: 'Developer', img: IvanKuzmenko },
        { id: 28, name: 'Maksym G.', position: 'Developer', img: MaksymGrytsuk },
        { id: 29, name: 'Maxim S.', position: 'Developer', img: MaximSobolev },
        { id: 30, name: 'Mykyta D.', position: 'Developer', img: MykytaDidenko },
        { id: 31, name: 'Oleksandr G.', position: 'Developer', img: OleksandrGribov },
        { id: 32, name: 'Petro R.', position: 'Developer', img: PetroRudenko },
        { id: 33, name: 'Sergey K.', position: 'Developer', img: SergeyKlymenko },
        { id: 34, name: 'Tatiana P.', position: 'Developer', img: TatianaPavlenko },
        { id: 35, name: 'Tetiana K.', position: 'Developer', img: TetianaKlymets },
        { id: 36, name: 'Valerii Z.', position: 'Developer', img: ValeriiZavaruiev },
        { id: 37, name: 'Volodymyr P.', position: 'Developer', img: VolodymyrPuchkov },
        { id: 38, name: 'Anna T.', position: 'Developer', img: AnnaTrofimova },
        { id: 39, name: 'Maksym T.', position: 'Junior Developer', img: MaksymTymchenko },
        { id: 40, name: 'Andrii O.', position: 'Data  Engineer', img: AndriiOvsiannikov },
        { id: 41, name: 'Anastasiia I.', position: 'Talent Acquisition', img: AnastasiiaIvanova },
        { id: 42, name: 'Karina D.', position: 'Office Administrator', img: KarinaDubok },
    ];
    return (
        <Layouts>
            <SEO
                pageTitle="Blackbird Lab - About Us"
                pageDescription="Blackbird Lab is a software development company that provides effective solutions to clients worldwide. Since 2018, we have been on a mission to deliver quality products..."
                pageImage="https://blackbird-image-cdn.s3.eu-central-1.amazonaws.com/about-us/about-us.png"
            />
            <div className="about-us about-us--mt">
                <div className="about-us__header">
                    <div className="about-us__text">
                        <SectionHeader
                            subtitleTextF="Developers,"
                            subtitleTextB="Problem solvers,"
                            subtitleTextS="Designers."
                            titleText="We Are Blackbird"
                            large="large"
                            typeValue="section"
                        />
                        <TextDescription type="mr">
                            Since 2018, Blackbird Lab has been on a mission to co-innovate and
                            deliver quality products that significantly enhance the lives of
                            thousands. We take immense pride in our track record of dozens of
                            success stories and efficient digital solutions. With a strong global
                            presence, operating both nearshore and offshore, we leverage the
                            exceptional talent and capabilities of teams based in Ukraine and
                            Colombia. Collaborating closely with our clients, we conceptualize,
                            design, and develop technology solutions that make a tangible impact.
                            Join us on this exciting journey as we continue to make a meaningful
                            difference and shape the future of technology
                        </TextDescription>

                        <div className="about-us__btn">
                            <a
                                className="main-title__desktop-link"
                                href="https://careers.blackbird-lab.com/en-GB"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Careers
                                <img
                                    className="main-title__desktop-img"
                                    src={triangleRight}
                                    alt="Careers"
                                />
                            </a>
                        </div>
                    </div>
                    <div className="about-us__img">
                        <img src={workingPeople} alt="Working people" />
                    </div>
                </div>
                <div className="about-us__map">
                    <img src={map} alt="Location of our offices" />
                </div>
            </div>

            <div className="about-us__gallery-bg">
                <div className="about-us__gallery">
                    <img src={office4} alt="Office photo" className="about-us__photo" />
                    <img
                        src={office1}
                        alt="Office photo"
                        className="about-us__photo about-us__photo--big"
                    />
                    <img
                        src={office3}
                        alt="Office photo"
                        className="about-us__photo about-us__photo--big"
                    />
                    <img src={office2} alt="Office photo" className="about-us__photo" />
                </div>
                <img src={aboutUsBG} alt="" className="about-us__bg" />
            </div>
            <div className="about-us about-us--m-mt">
                <div className="about-us__content">
                    <div className="about-us__content-wrapper">
                        <SectionTitle type="lighter" title="Superior process" />
                        <SectionTitle type="primary" title="This is how we solve" />
                        <TextDescription subtype="mb">
                            Blackbird is a team of enthusiastic problem-solvers with creative minds
                            and bright ideas. Each challenging task we approach with a wave of
                            excitement and motivation turning it into an innovative solution. During
                            our development journey, we focus on the smallest details, stay
                            open-minded and obsess over the client’s experience. Only in this way
                            can we deliver efficient life-changing software
                        </TextDescription>
                        <div className="about-us__btn">
                            <Link to="/how-we-work/">
                                <Button icon={arrRight} type="primary" desc="See our approach">
                                    See our approach
                                </Button>
                            </Link>
                        </div>
                    </div>
                    <div className="about-us__content-img">
                        <TextImage image={illustration} />
                    </div>
                </div>
                <SectionTitle type="lighter" title="Meet our team" />
                <PhotoIcon ourTeam={ourTeam} />
                <TextSpan
                    iconType="secondary"
                    buttonIcon={Job}
                    buttonLink="https://careers.blackbird-lab.com/en-GB"
                    buttonText="See open jobs"
                    title="Get in touch"
                >
                    Looking to become a part of our team?
                </TextSpan>
            </div>
            <FixedButton />
        </Layouts>
    );
};

export default aboutUs;
